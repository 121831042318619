@import '../../../../libs/static-pages/src/styles/main.scss';

html {
  box-sizing: border-box;
}

.tnm-v1 {
  font-size: 21px;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  line-height: 1;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // color: hsla(0, 0%, 0%, 0.8);
  // word-wrap: break-word;
  // font-kerning: normal;
  // -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  // -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  // -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  // font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}

* {
  box-sizing: inherit;
}

li {
  margin-bottom: 10px;
}

*::before {
  box-sizing: inherit;
}

*::after {
  box-sizing: inherit;
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}
